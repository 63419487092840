<template>
  <div class="container">
      <ul>
          <li v-for="item in list" :key="item.id" @click="goDetail(item.id)">
              <h5>{{item.title}}</h5>
              <p>{{item.createTime}}</p>
              <p>{{item.remark}}</p>
          </li>
      </ul>
  </div>
</template>

<script>
import {List} from '@/api/knowledge'
import isWebView from "@/mixins/webview.js"
export default {
    data(){
        return{
            list:[],
            form:{}
        }
    },
    mounted(){
         this.isWebView();
        this.getList()
    },
    mixins:[isWebView],
    methods:{
        async getList(){
            let res =await List()
            this.list = res.data.data.list
        },
        goDetail(id){
            this.$router.push('/epidemicKnowledge/'+ id)
        }
    }
}
</script>

<style scoped>
    .container{
        padding: 0.2rem;
        padding-top: 0.1rem;
    }
    ul{
        list-style: none;
    }
    ul li{
        border-bottom: 1px solid #CCCCCC;
        padding: 0.14rem 0;
    }
    ul li h5{
        color: #020202;
        font-size: 0.16rem;
        font-weight: normal;
    }
    ul li p{
        color: #999999;
        font-size: 0.14rem;
    }
</style>