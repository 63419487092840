import fetch from "../utils/fetch";

export function List(query) {
  return fetch({
    url: "/epKnowledge/page?ps=99999&enable=1",
    method: "GET",
    params: query,
  });
}

export function Detail(id) {
  return fetch({
    url: `/epKnowledge/${id}`,
    method: "GET",
  });
}
